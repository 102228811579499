// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
	  apiKey: "AIzaSyB4d0oOXpBrqCHhA4ekt4-6UZK7PqBaux8",
	  authDomain: "ofenstaunen1.firebaseapp.com",
	  projectId: "ofenstaunen1",
	  storageBucket: "ofenstaunen1.appspot.com",
	  messagingSenderId: "468595740224",
	  appId: "1:468595740224:web:2e5da99176581cb97719a5",
	  measurementId: "G-R816KGMWCM"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
