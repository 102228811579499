

<div class="layoutRowSpaceAround">
    <mat-card style="margin-top: 50px;">
        <mat-card-header>
            <mat-card-title>Pampered Chef</mat-card-title>
            <mat-card-subtitle>Küchenhelfer und Steine für beste Ergebnisse</mat-card-subtitle>
        </mat-card-header>
        <img style="width: 300px; max-height: 300px;" src="../../../../assets/pics/Brot.jpg" alt="Brot in der Backform">

        <mat-card-content>
            <a href="https://www.pamperedchef.eu/pws/OfenStaunen/store/DEO/catalog/HomeDE?bizname=OfenStaunen&BusinessName=OfenStaunen&OrderTypeId=DEO&">
                <button style="margin-top: 20px;" mat-raised-button>
                    <mat-icon aria-hidden="false" aria-label="Shopping Cart Icon" fontIcon="shopping_cart"></mat-icon>
                    zum Shop</button>
            </a>
        </mat-card-content>
    </mat-card>

    <mat-card style="margin-top: 50px;">
        <mat-card-header>
            <mat-card-title>Edelschmaus</mat-card-title>
            <mat-card-subtitle>Gewürze und Öle für den besten Geschmack</mat-card-subtitle>
        </mat-card-header>
        <img style="width: 300px; max-height: 300px;" src="../../../../assets/pics/Gewurz.jpg" alt="verschiedene Gewürze">
        <mat-card-content>
            <a href="https://emartens1.edelschmaus.com/shop">
                <button style="margin-top: 20px;" mat-raised-button>
                    <mat-icon aria-hidden="false" aria-label="Shopping Cart Icon" fontIcon="shopping_cart"></mat-icon>
                    zum Shop</button>
            </a>
        </mat-card-content>
    </mat-card>
</div>